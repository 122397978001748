import React, { useEffect, useState } from "react"
import moment from "moment"
import { BorderBox, Icon } from "components"
import { useParams, useNavigate } from "react-router-dom"
import { useReservationDetail } from "pages/Reports/libs/useReservationDetail"
import { JsonParserHelper } from "utils/jsonParserHelper"

import { StatusText } from "./components/StatusText"
import MainHeader from "components/Headers/MainHeader"
import ReservationReportsDetailPersonInfo from "./components/ReservationReportsDetailPersonInfo"
import ReservationReportsDetailResNote from "./components/ReservationReportsDetailResNote"
import ReservationReportsDetailModuleInfo from "./components/ReservationReportsDetailModuleInfo"
import ReservationReportsDetailInvoiceNote from "./components/ReservationReportsDetailInvoiceNote"
import ReservationReportDetailInfos from "./ReservationReportsDetailInfos"

const ReservationReportsDetail = () => {
  let navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading, isSuccess } = useReservationDetail(id)
  const [detailData, setDetailData] = useState()

  const handleOnNavigate = (path = "") => navigate("/reports/reservation-reports/" + path)

  useEffect(() => {
    setDetailData(data?.data?.result)
  }, [data?.data?.result])

  const reservationData = JsonParserHelper.jsonParseOrUndefined(detailData?.reservationData)
  const displayInformations = JsonParserHelper.jsonParseOrUndefined(detailData?.displayInformations)
  const searchRequest = JsonParserHelper.jsonParseOrUndefined(detailData?.searchRequest)
  const corpRequestHistories = JsonParserHelper.jsonParseOrUndefined(detailData?.corpRequestHistories)
  const status = detailData?.requestStatus

  const location = reservationData?.SearchResponse?.Items ? reservationData?.SearchResponse?.Items[0]?.Location : null
  const errorStatusText = corpRequestHistories?.find((item) => item.newStatus === data.requestStatus)
  const hotelImage = reservationData?.SearchResponse?.Items && reservationData?.SearchResponse?.Items?.[0]?.Images?.[0]?.Url

  return (
    <>
      <MainHeader />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          {isLoading && <div>Yükleniyor...</div>}
          {detailData && isSuccess && (
            <>
              <div
                onClick={() => handleOnNavigate()}
                className="go-back inline-flex items-center cursor-pointer"
              >
                <Icon
                  className="mr-2"
                  name="arrow-left"
                  size="20"
                  color="#667085"
                />
                <span className="text-base text-gray-500">Geri Dön</span>
              </div>
              <div className="flex flex-col mt-10 mb-3 gap-[11px]">
                {detailData?.moduleName === "HotelModule" && (
                  <h3 className="text-3xl text-gray-800 font-semibold">{`${location && location.split(",")[0]}${
                    detailData?.travelReasonName ? ` ${data?.travelReasonName}` : ""
                  } Seyahati`}</h3>
                )}
                {detailData?.moduleName === "FlightModule" &&
                  (() => {
                    const pricingCombinations = reservationData?.DetailResponse?.PricingCombinations
                    const flightSegments =
                      reservationData &&
                      pricingCombinations?.length > 0 &&
                      pricingCombinations[0] &&
                      pricingCombinations[0].CombinationGroups &&
                      pricingCombinations[0].CombinationGroups[0] &&
                      pricingCombinations[0].CombinationGroups[0].FlightSegments[0] &&
                      pricingCombinations[0].CombinationGroups[0].FlightSegments.sort(
                        (a, b) => moment(a.DepartureTime).valueOf() - moment(b.DepartureTime).valueOf(),
                      )
                    if (!flightSegments) {
                      return null
                    }
                    let arrivalSegment = flightSegments[flightSegments.length - 1]
                    return (
                      <h3 className="text-3xl text-gray-800 font-semibold">
                        {arrivalSegment?.Destination
                          ? `${arrivalSegment?.Destination.split(" ")[0]}${
                              data?.travelReasonName ? ` ${data?.travelReasonName}` : ""
                            } Seyahati`
                          : data?.travelReasonName}
                      </h3>
                    )
                  })()}

                <span className="text-gray-500">
                  {moment(detailData?.createDate).format("DD.MM.YYYY")} tarihinde oluşturuldu.
                </span>
              </div>
              <div className="flex flex-col gap-6">
                <BorderBox className="p-8">
                  <div className="flex flex-col md:justify-between lg:flex-row lg:items-center">
                    <div>
                      <p className="mb-2.5 font-medium text-gray-500">Onay Durumu</p>
                      <StatusText
                        status={status}
                        text={`${moment.utc(data.updateDate).format("DD MMMM YYYY (hh:mm A)")} tarihinde ${
                          data.updatedByUserName
                            ? `${
                                errorStatusText?.createdByFullName ? errorStatusText?.createdByFullName : data.updatedByUserName
                              } tarafindan`
                            : ``
                        } ${status === "denied" ? "Reddedildi" : status === "error" ? "iptal edildi" : "onaylandı"}.`}
                      />
                    </div>
                    {status === "denied" && errorStatusText?.description && (
                      <div>
                        <p className="mb-2.5 font-medium text-gray-500">Reddedilme Nedeni</p>
                        <p className="text-base text-gray-800">{errorStatusText?.description}</p>
                      </div>
                    )}
                  </div>
                </BorderBox>
                <ReservationReportDetailInfos
                  detailData={detailData}
                  displayInformations={displayInformations}
                  reservationData={reservationData}
                  searchRequest={searchRequest}
                />
                <ReservationReportsDetailPersonInfo data={detailData?.passengerInformations} />
                {reservationData?.ReservationNote && <ReservationReportsDetailResNote data={detailData} />}
                {reservationData?.ProjectCode && <ReservationReportsDetailInvoiceNote data={detailData} />}
                <ReservationReportsDetailModuleInfo
                  displayInformations={displayInformations}
                  searchRequest={searchRequest}
                  hotelImage={hotelImage}
                  reservationData={reservationData}
                  data={detailData}
                />
              </div>
            </>
          )}
        </section>
      </main>
    </>
  )
}

export default ReservationReportsDetail
