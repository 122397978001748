/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "components";
import {pageCloneRequested, pageCloneSucceed} from "redux/actions/pageClone";
import getPageList from "../../helpers/GetPageList";

const ClonePathAlert = (props) => {
	const {state, setState, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown, onSuccess, onError, noListRequest} = props
	const dispatch = useDispatch()

	const clonePage = useSelector((state) => state.pageClone.response)
	const clonePageLoading = useSelector((state) => state.pageClone.loading)
	const clonePageError = useSelector((state) => state.pageClone.error)

	const getPageClone = () => {
		const {Id, isCustom} = state
		dispatch(pageCloneRequested({
			Id,
			Type: (isCustom === "true" || isCustom) ? 2 : 1 // custom page olanlar için 2 olmayanlara 1.
		}))
	}

	useEffect(() => {
		if (clonePage || clonePageError){
			const staticErrorMessage = "güncelleme işlemi sırasında bir sorun oluştu, lütfen tekrar deneyiniz."
			if (clonePage?.Success) {
				dispatch(pageCloneSucceed(null))
				setState(null)
				if (!noListRequest) getPageList(dispatch, TableSearchQuery, ActivePageTypeDropdown, ActivePageStatusDropdown)
				if(onSuccess){
					onSuccess(true)
				}
			} else if(!clonePage?.Success){
				setState({
					...state,
					errorMessage: (clonePage?.Errors && clonePage?.Errors[0]?.Message) || staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			} else if (clonePageError){
				setState({
					...state,
					errorMessage: staticErrorMessage
				})
				if(onError){
					onError(true)
				}
			}
		}
	}, [clonePage, clonePageError])

	return (
		<>
			{state && (
				<Alert
					iconName={"copy"}
					approveButtonText={`${!state?.errorMessage ? "Kopyala" : "Tekrar dene"}`}
					title={`Sayfa Kopyalama`}
					rejectButtonOnClick={() => {
						dispatch(pageCloneSucceed(null))
						setState(null)
					}}
					approveButtonOnClick={getPageClone}
					approveButtonClass={"primary"}
					loading={clonePageLoading}
				>
					<p className="text-sm text-gray-500">
						<code className="text-red-500"><b>{state?.Name}</b></code>
						&nbsp;isimli ve
						&nbsp;<code className="text-red-500"><b>{state?.Path}</b></code>
						&nbsp;url adresli {state?.errorMessage ? state?.errorMessage.toLowerCase() || `kayıt kopyalanırken bir hata oluştu` : `kaydı kopyalamak istediğinize emin misiniz?`}</p>
				</Alert>
			)}
		</>
	)
}


export default ClonePathAlert