/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

//layouts
import PartialWrapper from "../../../../PartialWrapper"

//components
import { ColorBox, Subtitle, TextInput, /*ButtonStyleBox, RadiusBox,*/ ToggleBox } from "components"

//utils
import { isProdEnv } from "utils/environmentsCheck"
import {setSettings} from "helpers/setSettings";

//saga-request
import {newButton, BUTTON, HYPERLINK, newHyperLink} from "pages/PageManager/Enums";


const ButtonLayout = (props) => {
  let { showSubLayout, setShowSubLayout, editBlock, ThisComponent, setThisComponent } = props

  //states
  const [ThisSlider, setThisSlider] = useState(null)
  const [ThisButton, setThisButton] = useState(null)
  const [ThisHyperLink, setThisHyperLink] = useState(null)
  const [DefaultButtonData] = useState({
    text: "",
  })
  const [DefaultButtonVisual] = useState(null)
  const [DefaultHyperLinkInfo] = useState({
    target: "_blank",
    href: undefined,
  })
  const [ItemInfo, setItemInfo] = useState({
    data: DefaultButtonData,
    visual: DefaultButtonVisual
  })

  const [HyperLinkInfo, setHyperLinkInfo] = useState(DefaultHyperLinkInfo)

  const goBackHandleClick = () => {
    setShowSubLayout(null)
    setItemInfo({
      data: DefaultButtonData,
      visual: DefaultButtonVisual
    })
    setHyperLinkInfo(DefaultHyperLinkInfo)
  }

  const setItemInfoFromStrategy = () => {
    if (ThisButton?.data || ThisButton?.visual){
      setItemInfo({
        data: ThisButton.data || DefaultButtonData,
        visual: ThisButton.visual?.style || DefaultButtonVisual
      })
    }
    if (ThisHyperLink?.data){
      setHyperLinkInfo({
        ...ThisHyperLink.data
      })
    }
  }


  const getButton = (ThisSlider) => {
    return ThisSlider?.children?.find(child => child?.name === BUTTON)
  }
  const getHyperLink = (ThisSlider) => {
    return ThisSlider?.children?.find(child => child?.name === HYPERLINK)
  }

  const checkHasChildren = () => {
    return !!ThisSlider?.children
  }

  const getThisSliderChildrenLength = (ThisSlider) => {
    return (ThisSlider?.children && ThisSlider?.children?.length) || 0
  }

  useEffect(() => {
    if (showSubLayout?.buttonLayout){
      setItemInfoFromStrategy()
    }
  }, [showSubLayout?.buttonLayout])

  useEffect(() => {
    if (editBlock?.slider){
      const thisSlider = ThisComponent?.children?.find(child => child?.code === editBlock?.slider?.code)
      setThisSlider({
        ...thisSlider,
      })
      setThisButton(thisSlider?.children?.find(child => child.name === BUTTON) || newButton(editBlock?.itemInfo?.buttonId))
      setThisHyperLink(thisSlider?.children?.find(child => child.name === HYPERLINK) || newHyperLink(editBlock?.itemInfo?.hyperLinkId))
    }
  }, [editBlock, showSubLayout?.buttonLayout])

  useEffect(() => {
    if ((JSON.stringify(HyperLinkInfo) !== JSON.stringify(DefaultHyperLinkInfo) || JSON.stringify(ItemInfo?.data) !== JSON.stringify(DefaultButtonData)) && showSubLayout?.buttonLayout){
      let buttonComponent = getButton(ThisSlider)
      let hyperlinkComponent = getHyperLink(ThisSlider)
      if (!buttonComponent){
        buttonComponent = ThisButton
        const hasChildren = checkHasChildren()
        if (!hasChildren) ThisSlider.children = []
        ThisSlider.children[getThisSliderChildrenLength(ThisSlider)] = ThisButton
        setThisSlider({
          ...ThisSlider
        })
      }
      if (!hyperlinkComponent){
        hyperlinkComponent = ThisHyperLink
        const hasChildren = checkHasChildren()
        if (!hasChildren) ThisSlider.children = []
        ThisSlider.children[getThisSliderChildrenLength(ThisSlider)] = ThisHyperLink
        setThisSlider({
          ...ThisSlider
        })
      }
      buttonComponent.data = ItemInfo?.data
      if (!buttonComponent.visual) buttonComponent.visual = {}
      buttonComponent.visual.style = ItemInfo?.visual
      hyperlinkComponent.data = HyperLinkInfo
      let updateSliderIndex = ThisComponent.children.findIndex(child => child?.code === ThisSlider?.code)
      ThisComponent.children[updateSliderIndex] = ThisSlider
      setThisComponent({
        ...ThisComponent
      })
    }
  }, [HyperLinkInfo, ItemInfo, showSubLayout?.buttonLayout])

  if (!showSubLayout?.buttonLayout){
    return false
  }

  return (
    <PartialWrapper
      className={`button-layout${showSubLayout?.buttonLayout ? " active" : ""}`}
      goBackOnClick={goBackHandleClick}
    >
      <Subtitle>Buton</Subtitle>

      {/*<pre>{JSON.stringify(ItemInfo, null, 2)}</pre>*/}

      {ItemInfo && (
        <div className="flex flex-col gap-4">
          <div className="input-wrapper">
            <ToggleBox
              title={"Buton Görünürlüğü"}
              checked={ItemInfo?.visual?.display !== "none"}
              onToggle={(e) => {
                setSettings({ target: {
                    name: "display",
                    value: e ? "block" : "none"
                  }}, false, ItemInfo, "visual").then((res) => setItemInfo(res))
              }}
            />
          </div>

          <div className="input-wrapper">

            <TextInput
              title={"Metin"}
              placeholder={ItemInfo?.data?.text || "Buton başlığı giriniz"}
              value={ItemInfo?.data?.text}
              name="text"
              onChange={(e) =>
                setSettings(e, true, ItemInfo, "data").then((res) => setItemInfo(res))
              }
            />
          </div>

          <div className="input-wrapper">
            <TextInput
              title={"Bağlantı"}
              placeholder={HyperLinkInfo?.href || "https://..."}
              value={HyperLinkInfo?.href}
              name="href"
              onChange={e => setSettings(e, true, HyperLinkInfo).then((res) => setHyperLinkInfo(res))}
            />
          </div>

          {!isProdEnv && (
            <>
              <div className="input-wrapper">
                <ToggleBox
                  title={"Yeni Sekmede Aç"}
                  name="target"
                  checked={HyperLinkInfo?.target === "_blank"}
                  onToggle={e => setSettings({ target: {
                      name: "target",
                      value: e ? "_blank" : "_self"
                    }}, true, HyperLinkInfo).then((res) => setHyperLinkInfo(res))}
                />
              </div>

              <div className="input-wrapper mb-5">
                <ColorBox
                  name="color"
                  title={"Renk"}
                  defaultValue={ItemInfo?.visual?.color}
                  onChange={(e) =>
                    setSettings(e, false, ItemInfo, "visual").then((res) => setItemInfo(res))
                  }
                />
              </div>

              {/*<div className="input-wrapper">
                <ButtonStyleBox
                  title="Stil"
                  ActiveButtonStyle={ButtonStyle}
                  onChange={(style) => {
                    setButtonStyle(style)
                  }}
                />
              </div>

              <div className="input-wrapper">
                <RadiusBox
                  title="Köşe Stili"
                  min={0}
                  max={40}
                  ActiveButtonRadius={ButtonRadius}
                  onChange={(radius) => {
                    setButtonRadius(radius)
                  }}
                  rangeLabel={"Radius (px)"}
                  rangeValue={ButtonRadius}
                  onRangeChange={(radius) => {
                    setButtonRadius(radius)
                  }}
                />
              </div>*/}
            </>
          )}
        </div>
      )}
    </PartialWrapper>
  )
}

export default ButtonLayout
